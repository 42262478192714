<template>
   <div id="toastButton">
      <span class="toast-message">
         {{ message }}
      </span>
      <button
         v-on:click="handleClick"
         class="confirm-delete-button ultra-light-bg"
      >
         Confirm
      </button>
   </div>
</template>

<script>
   export default {
      name: 'ToastButton',
      props: {
         message: {
            type: String,
            default: ""
         },
         buttonClickCallback: {
            type: Function,
            default: () => {}
         },
      },
      methods: {
         handleClick(e) {
            e.preventDefault()
            this.buttonClickCallback()
         },
      }
   }
</script>

<style scoped>
   #toastButton {
      font-size: 0.8rem;
      margin-left: 1em;
   }
   .confirm-delete-button {
      padding: 0.4em 0.6em;
      font-size: 1.1em;
      border-radius: 0.2em;
      margin-right: 0.5em;
      margin-left: 1.5em;
      position: relative;
      top: 0.2em;
   }
   .toast-message {
      float: left;
      font-family: sans-serif;
      font-weight: 700;
      font-size: 1.27em; /* to match default appearance w/ custom style */
      color: #fff;
      line-height: 2.3em;
   }
</style>
