<template>
   <div id="controlBar" :class="`${position} ${border}`">
      <button
         v-for="btn in buttons"
         v-on:click="btn.onClickCallback"
         :key="`btn-${btn.text}`"
         :class="`control-bar-button ${btn.variant}-bg`"
         :disabled="btn.disabled"
      >
         <FontAwesomeIcon
            v-if="btn.icon"
            :icon="getIcon(btn.icon)"
         />
         {{ btn.text }}
      </button>
   </div>
</template>

<script>
   // Import UI components
   import { faPlus, faPlusSquare, faThumbsUp
      , faThumbsDown, faTrash } from '@fortawesome/free-solid-svg-icons'
   // Export components
   export default {
      name: 'ControlBar',
      props: {
         buttons: {
            type: Array,
            default: () => []
         },
         position: {
            type: String,
            default: 'center'
         },
         border: {
            type: String,
            default: ''
         },
      },
      data: () => {
         return {
            faPlus: faPlusSquare,
         }
      },
      methods: {
         getIcon(name) {
            let icon = null
            switch(name) {
               case 'plus':
                  icon = faPlus
                  break;
               case 'plusSquare':
                  icon = faPlusSquare
                  break;
               case 'thumbsUp':
                  icon = faThumbsUp
                  break;
               case 'thumbsDown':
                  icon = faThumbsDown
                  break;
               case 'delete':
                  icon = faTrash
                  break;
               default: break;
            }
            return icon
         },
      },
   }
</script>

<style scoped>
   #controlBar {
      text-align: center;
      padding: 0;
      border-radius: 3px;
   }
   #controlBar.right {
      text-align: right;
      margin: 0;
   }
</style>
