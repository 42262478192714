<template>
   <div>
      <ul id="tagList">
         <li
            v-for="tag in tags"
            v-on:click="handleTagClick(tag)"
            :key="`tag-${tag.name}`"
            :class="`watermark-border ${tag.id === selectedTag.id ? 'highlight-bg' : 'light-bg'}`"
         >
            {{ tag.name }}
         </li>
      </ul>
   </div>
</template>

<script>
   export default {
      name: 'TagList',
      props: {
         tags: {
            type: Object,
            default: () => {}
         },
         tagClickCallback: {
            type: Function,
            default: () => {}
         },
      },
      data: () => {
         return {
            selectedTag: {
               id: -1,
            },
         }
      },
      methods: {
         handleTagClick(tag) {
            // Inform parent of tag selection
            this.tagClickCallback(tag)
            this.selectedTag = tag
         },
      },
   }
</script>

<style scoped>
   #tagList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style-type: none;
      margin: 1em 0;
   }
   #tagList li {
      display: inline-block;
      margin: 0.5em 1em;
      padding: 1em;
      font-size: 0.9em;
   }
   #tagList li:hover {
      background-color: #eef9ff;
   }
</style>
