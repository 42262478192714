<template>
   <div class="highlight-border light-bg">
      <h3>
         {{ title }}
         <div class="edit-label muted">
            Click to edit
         </div>
      </h3>
      <ul
         v-if="hasQuestions"
         class="list-nav"
      >
         <li
            v-for="question in questions"
            v-on:click="handleQuestionClick(question.id)"
            :id="`question-${question.id}`"
            :key="question.name"
            :class="Object.keys(selectedItems).includes(`question-${question.id}`) ? 'selected' : ''"
         >
            <span class="line-heading">{{question.id}}</span>
            <div class="question-text">
               {{ question.text }}
            </div>
         </li>
      </ul>
      <div
         v-if="!hasQuestions"
         class="info-message site-bg"
      >
         <h4>{{ infoMessage }}</h4>

      </div>
   </div>
</template>

<script>
   // Component level constants
   const defaultInfoMessage = 'Select a tag to view questions'
   // Export component
   export default {
      name: 'QuestionList',
      props: {
         title: {
            type: String,
            default: () => {}
         },
         emptyListMessage: {
            type: String,
            default: () => { return defaultInfoMessage }
         },
         questions: {
            type: Object,
            default: () => {}
         },
         isLoading: {
            type: Boolean,
            default: false
         },
         questionClickCallback: {
            type: Function,
            default: () =>  {}
         }
      },
      data: () => {
         return {
            selectedItems: {} // left for future multi-select function
         }
      },
      computed: {
         infoMessage: function() {
            return this.isLoading ? 'Loading questions...' : this.emptyListMessage
         },
         hasQuestions: function() {
            return Object.keys(this.questions).length > 0
         },
      },
      methods: {
         isEmpty() {
            return Object.keys(this.selectedItems).length === 0
         },
         handleQuestionClick(id) {
            // Bubble up to parent handler
            this.questionClickCallback(id)
         }
      },
   }
</script>

<style scoped>
   ul {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;
      list-style-type: none;
   }
   ul li {
      padding: 1em 0.5em 0.7em;
      border-bottom: 1px solid;
      background-color: #fff;
   }
   ul li:hover {
      background-color: #f7f7f7;
   }
   ul li.selected {
      background-color: #ceb641;
   }
   h3 {
      text-align: center;
      font-size: 1.5em;
      padding: 0.5em 1em;
      margin: 0;
      border-bottom-style: solid;
      border-bottom-width: 2px;
      position: relative;
   }
   .question-text {
      display: inline-block;
      margin-left: 1em;
   }
   .edit-label {
      position: absolute;
      right: 1em;
      top: 1.2rem;
      font-size: 1rem;
   }
   .line-heading {
      position: absolute;
      left: 0.5em;
      font-weight: 600;
   }
   .question-text {
      margin-left: 4.5rem;
   }
</style>
