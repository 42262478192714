<template>
   <div
      id="pagination"
      v-if="show"
      :class="showBorder ? 'bottom-border' : 'none'"
   >
      <FontAwesomeIcon
         :icon="doubleLeftIcon"
         v-on:click="firstPage"
      />
      <FontAwesomeIcon
         :icon="leftIcon"
         v-on:click="previousPage"
      />
      <form id="optionsForm">
         <div class="pagination-label">
            Page Size
         </div>
         <select
            class="site-bg"
            v-on:change="changePageSize"
         >
            <option
               v-for="opt in pageSizeOptions"
               :key="`page-size-${opt}`"
               :value="opt"
               :selected="opt == pageSize"
            >
               {{ opt }}
            </option>
         </select>
      </form>
      <FontAwesomeIcon
         :icon="rightIcon"
         v-on:click="nextPage"
      />
      <FontAwesomeIcon
         :icon="doubleRightIcon"
         v-on:click="lastPage"
      />
      <div id="pageNumber">
         {{ page }} / {{ totalPages }}
      </div>
   </div>
</template>

<script>
   // Import UI components
   import { faStepBackward, faFastBackward
      , faStepForward, faFastForward } from '@fortawesome/free-solid-svg-icons'
   // Component-level constants
   const defaultPage = 1
   const defaultPageSize = 10
   // Export component
   export default {
      name: 'Pagination',
      props: {
         show: {
            type: Boolean,
            default: () => true
         },
         showBorder: {
            type: Boolean,
            default: () => true
         },
         itemCount: {
            type: Number,
            default: 0
         },
         totalItems: {
            type: Number,
            default: defaultPageSize
         },
         pageSizeOptions: {
            type: Array,
            default: () => [10, 25, 50]
         },
         pageChangeCallback: {
            type: Function,
            default: () => {}
         }
      },
      constants: {
         defaultPage: defaultPage,
         defaultPageSize: defaultPageSize,
      },
      data: () => {
         return {
            page: defaultPage,
            pageSize: defaultPageSize,
            totalPage: defaultPageSize,
            leftIcon: faStepBackward,
            doubleLeftIcon: faFastBackward,
            rightIcon: faStepForward,
            doubleRightIcon: faFastForward,
         }
      },
      computed: {
         totalPages: function () {
            return Math.ceil(this.totalItems / this.pageSize)
         }
      },
      methods: {
         nextPage() {
            this.page = Math.min(this.page + 1, this.totalPages)
            this.pageChangeCallback(this.page, this.pageSize)
         },
         firstPage() {
            this.page = 1
            this.pageChangeCallback(this.page, this.pageSize)
         },
         previousPage() {
            this.page = Math.max(this.page - 1, 1)
            this.pageChangeCallback(this.page, this.pageSize)
         },
         lastPage() {
            this.page = this.totalPages
            this.pageChangeCallback(this.page, this.pageSize)
         },
         changePageSize(e) {
            e.preventDefault()
            this.pageSize = parseInt(e.target.value) ?? defaultPageSize
            this.pageChangeCallback(this.page, this.pageSize)
         },
      }
   }
</script>

<style scoped>
   select {
      padding: 0.5em;
      border-radius: 0.3em;
   }
   svg {
      margin: 0 0.4em;
   }
   svg:hover {
      opacity: 0.7;
   }
   #pagination {
      font-size: 1.5em;
      text-align: center;
      margin: 0 0.5em;
      padding: 1em 0.5em;
   }
   #pageNumber {
      float: right;
      font-weight: 500;
      font-size: 1.0em;
      position: relative;
      top: 1.5rem;
   }
   #optionsForm {
      max-width: 7rem;
      font-size: 1.5em;
      display: inline-block;
      position: relative;
      top: -0.1em;
   }
   .pagination-label {
      font-size: 0.8rem;
      font-weight: 600;
      position: relative;
      top: 0.5em;
   }
   .bottom-border {
      border-bottom-color: #999;
      border-bottom-style: solid;
      border-bottom-width: 1px;
   }
</style>
