<template>
   <div
      id="optionsWrapper"
      class="site-bg"
   >
      <!-- Options list -->
      <div class="flex-grid">
         <div
            v-if="!questionAlreadyExists"
            class="col-xs col-label muted"
         >
            Options
         </div>
         <div
            v-if="!questionAlreadyExists"
            class="col-md"
         >
            <ul
               id="optionList"
               class="option-list"
            >
               <li
                  v-for="opt in options"
                  :key="`option-${opt.id}`"
                  :class="`ultra-light-bg dark-border ${opt.is_correct ? 'correct' : ''}`"
               >
                  {{ opt.option_text }}
                  <div class="option-caption">
                     <FontAwesomeIcon
                        v-if="opt.is_correct"
                        :icon="correctIcon"
                        id="correctIcon"
                     />
                     {{ opt.is_correct ? 'Correct answer' : '' }}
                  </div>
                  <div id="optionButtons">
                     <button
                        v-on:click="handleOptionDelete(opt.id)"
                        class="option-button danger-button"
                     >
                        <FontAwesomeIcon
                           :icon="deleteIcon"
                        />
                     </button>
                  </div>
               </li>
            </ul>
            <div v-if="!questionAlreadyExists && (!options || options.length === 0)">
               No options yet
            </div>
         </div>
         <div :class="`${!questionAlreadyExists ? 'col-xs' : 'col-md text-right pad-right-1'}`">
            <div id="optionControlButtons">
               <button
                  :disabled="showOptionEditor"
                  v-on:click="handleOptionCreate"
                  class="primary-button highlight-bg control-button"
               >
                  New
               </button>
            </div>
         </div>
      </div>
      <!-- Option editor -->
      <div
         id="optionEditor"
         v-if="showOptionEditor"
         class="flex-grid pad-y-1"
      >
         <div class="col-xs col-label muted">
            New Option
         </div>
         <!-- form column -->
         <div class="col-md pad-right-1">
            <div id="newOptionForm">
               <form>
                  <label>
                     Text
                  </label>
                  <textarea
                     v-model="optionText"
                     class="option"
                  >
                  </textarea>
                  <div
                     id="isCorrectControl"
                  >
                     <label>Correct?</label>
                     <input
                        type="checkbox"
                        v-model="isCorrect"
                     />
                  </div>
               </form>
            </div>
         </div><!-- form column-->
         <div class="col-xs">
            <div>
               <button
                  v-on:click="handleSaveOption"
                  class="primary-button highlight-bg control-button"
               >
                  Save
               </button>
               <button
                  v-if="showOptionEditor"
                  v-on:click="handleCancelOptionEdit"
                  class="ultra-light-bg control-button"
               >
                  Cancel
               </button>
            </div>
         </div>
      </div>
   </div><!-- optionsWrapper -->
</template>

<script>
   // Import functions
   import { inject } from 'vue'
   import { clearToasts } from 'mosha-vue-toastify'
   import { deleteOption } from '@/api/questions.js'
   import { postOptionUnapproved } from '@/api/approvals.js'
   import { makeToast } from '@/api/toast.js'
   // Import UI components
   import { faTrash, faEdit, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
   // import ToastButton from '@/components/ToastButton.vue';

   // Import styles
   import '@/assets/css/options.css'
   // Export component
   export default {
      name: 'OptionMaker',
      props: {
         currentOption: {
            type: Object,
            default: () => {}
         },
         question: {
            type: Object,
            default: () => {}
         },
         updateOptionsCallback: {
            type: Function,
            default: () => {}
         },
         refreshQuestionCallback: {
            type: Function,
            default: () => {}
         },
         questionAlreadyExists: {
            type: Boolean,
            default: false
         },
      },
      data: () => {
         return {
            editIcon: faEdit,
            deleteIcon: faTrash,
            correctIcon: faCheckCircle,
            showOptionEditor: false,
            optionText: null,
            isCorrect: null,
            format: 'text',
            options: [],
            optionIndex: 0,
            refreshComputedCount: 0,
         }
      },
      computed: {
      },
      components: {
      },
      methods: {
         handleOptionCreate() {
            this.showOptionEditor = true
         },
         handleSaveOption() {
            if(!this.questionAlreadyExists) {
               this.handleSaveOptionLocal()
               return
            }
            const params = {
               question_id: this.question.id,
               axios: this.axios,
               data: {
                  option_text: this.modifiedOptionText ?? this.optionText,
                  is_correct: this.modifiedIsCorrect ?? this.isCorrect,
                  format: this.format,
               },
               callback: (res) => {
                  if(res.status === 201) {
                     makeToast('Option created', 'success')
                     // Reset form
                     this.clearEditor()
                     // Request parent update question from API
                     this.refreshQuestionCallback()
                  } else {
                     makeToast(res.response.data.data.public, 'danger')
                  }
               },
            }
            // Make request to save option
            postOptionUnapproved(params)
         },
         handleSaveOptionLocal() {
            // Save option locally
            const newOption = {
               id: this.optionIndex,
               option_text: this.optionText,
               is_correct: this.isCorrect,
               format: 'text'
            }
            this.options.push(newOption)
            this.optionIndex += 1 // for local option unique ID
            // Update parent with new option
            this.updateOptionsCallback(this.options)
            // Reset editor
            this.clearEditor()
         },
         clearEditor() {
            this.showOptionEditor = false
            this.optionText = null
            this.isCorrect = null
         },
         handleCancelOptionEdit() {
            // Reset editor
            this.clearEditor()
         },
         handleOptionEdit(opt) {
            // Select an option to edit
            this.selectedOption = opt
            this.showOptionEditor = true
            // Reset data model
            this.optionText = opt.option_text
            this.modifiedIsCorrect = opt.is_correct
            // Trigger refresh of computed properties
            this.refreshComputedCount = this.refreshComputedCount + 1
         },
         handleOptionDelete(id) {
            // Find option in local array, delete, and update data
            const i = this.options.findIndex(val => val.id === id)
            const tempOptions = this.options.slice(0, i).concat(this.options.slice(i + 1))
            this.options = tempOptions
         },
         // Delete option confirmed
         confirmDeleteOptionCallback(id) {
            const params = {
               id: id,
               axios: this.axios,
               callback: (res) => {
                  if(res.status === 201) {
                     clearToasts() // clear confirm toast
                     makeToast('Option deleted', 'success')
                  } else if(res.response.status >= 400) {
                     makeToast(res.response.data.data.public, 'danger')
                  }
               }
            }
            // Make request to delete option
            deleteOption(params)
         },
      },
      setup() {
         // Inject axios and provide as prop to component for use with API calls
         const $axios = inject('$axios')
         return {
            axios: $axios
         }
      }
   }
</script>

<style scoped>
   textarea.option {
      width: calc(90% - 5em);
      height: 3.5em;
      margin: 0 1em;
      resize: none;
   }
   label {
      font-size: 1.1em;
      font-weight: 600;
      width: 5em;
      display: inline-block;
   }
   #optionEditor {
      margin: 0 auto;
      border-radius: 3px;
   }
   #optionList {
      max-width: 90%;
      margin: 0 auto;
   }
   #newOptionForm {
      padding-left: 3em;
      text-align: left;
   }
</style>
