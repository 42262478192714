import * as Utils from './utils.js'

export function getNavigationOptions(router) {
   if(Utils.isDebug()) console.log('getNavigationOptions')
   return [
      {
         text: 'View Questions',
         onClickCallback: () => {
            router.push({ name: 'questions' })
         }
      },
      {
         text: 'Approval Queue',
         onClickCallback: () => {
            router.push({ name: 'approvals' })
         }
      }
   ]
}
