
import * as Utils from './utils.js'
import { $appConfig } from '@/config/app.js'
import { inject } from 'vue'

/*
* Get all tags with # of questions for each.
*/
export function getTags(params) {
   const $axios = inject('$axios')
   if(Utils.isDebug()) console.log('getTags', params)
   // Call get tags endpoint
   $axios.get(`${$appConfig.api.url}/managers/tags`)
      .then(res => {
         if(params.callback) params.callback(res.data)
         return res.data
      }).catch(err => {
         console.log('getTags error', err)
      })
}

/*
* Put a question into a tag.
*/
export function putQuestionIntoTag(params) {
   if(Utils.isDebug()) console.log('putQuestionIntoTag', params)
   // Call API
   params.axios.put(`${$appConfig.api.url}/managers/questions/${params.question_id}/tag/${params.tag_id}`)
      .then(res => {
         if(params.callback) params.callback(res)
         return res
      }).catch(err => {
         console.log('putQuestionIntoTag error', err)
         if(params.callback) params.callback(err)
      })
}

/*
* Delete a question from a tag.
*/
export function deleteQuestionFromTag(params) {
   if(Utils.isDebug()) console.log('deleteQuestionFromTag', params)
   // Call API
   params.axios.delete(`${$appConfig.api.url}/managers/questions/${params.question_id}/tag/${params.tag_id}`)
      .then(res => {
         if(params.callback) params.callback(res)
         return res
      }).catch(err => {
         console.log('deleteQuestionFromTag error', err)
         if(params.callback) params.callback(err)
      })
}
