import * as Utils from './utils.js'
import { $appConfig } from '@/config/app.js'

/*
* Get paginated list of unapproved questions.
*/
export function getUnapprovedQuestions(params) {
   if(Utils.isDebug()) console.log('getUnapprovedQuestions', params)
   // Call API
   params.axios.get(`${$appConfig.api.url}/managers/approval/questions/unapproved?page=${params.page}&size=${params.pageSize}`)
      .then(res => {
         if(params.callback) params.callback(res)
         return res
      }).catch(err => {
         console.log('getUnapprovedQuestions error', err)
         if(params.callback) params.callback(err.response)
      })
}

/*
* Create a new unapproved question.
*/
export function postQuestion(params) {
   if(Utils.isDebug()) console.log('postQuestion', params)
   // Call API
   params.axios.post(`${$appConfig.api.url}/managers/approval/questions/unapproved`
         , params.data)
      .then(res => {
         if(params.callback) params.callback(res)
         return res
      }).catch(err => {
         console.log('postQuestion error', err)
         if(params.callback) params.callback(err)
      })
}

/*
* Create an option for an unapproved question.
*/
export function postOptionUnapproved(params) {
   if(Utils.isDebug()) console.log('postOption', params)
   // Call API
   params.axios.post(`${$appConfig.api.url}/managers/approval/questions/${params.question_id}/option`
         , params.data)
      .then(res => {
         if(params.callback) params.callback(res)
         return res
      }).catch(err => {
         console.log('postOption error', err)
         if(params.callback) params.callback(err)
      })
}

/*
* Get a question.
*/
export function getQuestion(params) {
   if(Utils.isDebug()) console.log('getQuestion', params)
   // Call API
   params.axios.get(`${$appConfig.api.url}/managers/approval/questions/${params.question_id}/unapproved`)
      .then(res => {
         if(params.callback) params.callback(res)
         return res
      }).catch(err => {
         console.log('getQuestion error', err)
         if(params.callback) params.callback(err)
      })
}

/*
* Approve a question.
*/
export function putQuestionApprove(params) {
   if(Utils.isDebug()) console.log('putQuestionApprove', params)
   // Call API
   params.axios.put(`${$appConfig.api.url}/managers/approval/questions/${params.question_id}/approve`
         , params.data)
      .then(res => {
         if(params.callback) params.callback(res)
         return res
      }).catch(err => {
         console.log('putQuestionApprove error', err)
         if(params.callback) params.callback(err)
      })
}

/*
* Disapprove a question.
*/
export function putQuestionDisapprove(params) {
   if(Utils.isDebug()) console.log('putQuestionDisapprove', params)
   // Call API
   params.axios.put(`${$appConfig.api.url}/managers/approval/questions/${params.question_id}/unapprove`
         , params.data)
      .then(res => {
         if(params.callback) params.callback(res)
         return res
      }).catch(err => {
         console.log('putQuestionDisapprove error', err)
         if(params.callback) params.callback(err)
      })
}

export function deleteQuestion(params) {
   console.log(params)
}

/*
* Delete option from unapproved question.
*/
export function deleteOptionFromUnapproved(params) {
   if(Utils.isDebug()) console.log('deleteOptionFromUnapproved', params)
   // Call API
   params.axios.delete(`${$appConfig.api.url}/managers/approval/questions/${params.question_id}/option/${params.option_id}`)
      .then(res => {
         if(params.callback) params.callback(res)
         return res
      }).catch(err => {
         console.log('deleteOptionFromUnapproved error', err)
         if(params.callback) params.callback(err)
      })
}
