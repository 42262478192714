<template>
   <div
      id="navigation"
      class="muted"
   >
      <div class="flex-grid">
         <div class="col-md">
            <FontAwesomeIcon
               :icon="navIcon"
               id="navIcon"
            />
            <ul>
               <li
                  v-for="opt in options"
                  v-on:click="opt.onClickCallback"
                  v-bind:key="`opt-${opt.text}`"
               >
                  {{ opt.text }}
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>

<script>
   // Import UI components
   import { faBars } from '@fortawesome/free-solid-svg-icons'
   // Export component
   export default {
      name: 'Navigation',
      props: {
         /*
         * [{ text, onClickCallback }, ...]
         */
         options: {
           type: Array,
           default: () => []
         },
      },
      data: () => {
         return {
            navIcon: faBars
         }
      },
      components: {
      },
      methods: {
      }
   }
</script>

<style scoped>
   ul {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;
      list-style-type: none;
      font-size: 1.2rem;
   }
   ul li {
      display: inline-block;
      padding: 0.5em 1em 0.3em;
      border-radius: 3px;
   }
   ul li:hover {
      background-color: #f7f7f7;
   }
   ul li.selected {
      background-color: #aedaf0;
   }
   #navigation  {
      text-align: left;
      padding: 0 1em 1em 0.5em;
      margin: 0;
   }
   #navIcon {
      float: left;
      font-size: 1.7em;
      padding-top: 0.3rem;
      padding-left: 1.5rem;
      padding-right: 1rem;
   }
</style>
