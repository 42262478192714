<template>
   <div
      id="questionEditor"
      class="light-bg highlight-border"
   >
      <h3>
         <button
            v-on:click="previousViewCallback"
            class="small-button ultra-light-bg"
         >
            <FontAwesomeIcon
               :icon="leftIcon"
            />
            Back
         </button>
         Question {{ question.id }}
         <div class="tag-name">
             {{ currentTag.name }}
         </div>
      </h3>
      <div
         id="editorWrapper"
         class="border-top site-bg"
      >
         <!-- Text row -->
         <div class="flex-grid">
            <div class="col-xs col-label muted">
               Text
            </div>
            <div class="col-md">
               <form>
                  <textarea v-model="questionText">
                  </textarea>
               </form>
            </div>
            <div class="col-xs text-right">
               <div id="textControlButtons">
                  <button
                     v-on:click="handleSaveQuestion"
                     class="highlight-bg control-button"
                  >
                     Save
                  </button>
                  <br/>
                  <button
                     v-on:click="handleCancelEdit"
                     class="ultra-light-bg control-button"
                  >
                     Cancel
                  </button>
               </div>
            </div>
         </div>
         <!-- Source row -->
         <div class="flex-grid">
            <div class="col-xs col-label muted">
               Source
            </div>
            <div class="col-md">
               <form>
                  <textarea
                     v-model="questionSource"
                     class="small"
                  >
                  </textarea>
               </form>
            </div>
            <div class="col-xs text-right"></div>
         </div>
         <hr/>
         <!-- Add tag row -->
         <div
            class="flex-grid"
         >
            <div class="col-xs col-label muted">
               Tags
            </div>
            <div class="col-md">
               <div id="tagSelector">
                  <form>
                     <select
                        v-on:click="handleSelectTag"
                        class="ultra-light-bg"
                     >
                        <option
                           v-for="tag in tags"
                           :id="`tag-${tag.id}`"
                           :key="`tag-${tag.id}`"
                        >
                           {{ tag.name }}
                        </option>
                     </select>
                  </form>
               </div>
            </div>
            <div class="col-xs">
               <button
                  class="primary-button highlight-bg"
                  v-on:click="handleAddTag"
               >
                  Add
               </button>
            </div>
         </div>
         <!-- Next 2 sections don't show for unapproved questions -->
         <!-- Tag list row -->
         <div
            class="flex-grid"
         >
            <div class="col-xs col-label muted">
               All
            </div>
            <div class="col-md">
               <TagList
                  v-bind:tags="question.tags"
                  v-bind:tagClickCallback="tagClickCallback"
               />
            </div>
            <div class="col-xs"></div>
         </div>
         <!-- Current tag row -->
         <div
            v-if="!questionIsUnapproved"
            class="flex-grid"
         >
            <div class="col-xs col-label muted">
               Remove
            </div>
            <div class="col-md text-right">
               <div
                  v-if="tagListSelected"
                  class="tag-label"
               >
                  {{ tagListSelected.name }}
               </div>
            </div>
            <div class="col-xs">
               <button
                  v-if="currentTag.id !== -1"
                  v-on:click="handleDeleteTag"
                  class="primary-button highlight-bg"
               >
                  Delete
               </button>
            </div>
         </div>
         <hr/>
         <!-- End approved-only section -->
         <OptionEditor
            v-bind:question="question"
            v-bind:refreshQuestionCallback="refreshQuestionCallback"
         />
         <OptionMaker
            v-if="!questionAlreadyExists"
            v-bind:question="question"
            v-bind:questionAlreadyExists="'true'"
            v-bind:refreshQuestionCallback="refreshQuestionCallback"
         />
         <hr/>
         <div class="flex-grid no-pad">
            <div class="col-xs col-label muted">
               Actions
            </div>
            <div class="col-md text-right pad-right-1">
               <ControlBar
                  v-bind:buttons="controlButtons"
                  v-bind:position="'right'"
               />
            </div>
         </div>
      </div><!-- editorWrapper -->
   </div>
</template>

<script>
   // Import functions
   import { inject } from 'vue'
   import { putQuestionIntoTag, deleteQuestionFromTag } from '@/api/tags.js'
   import { putQuestion, deleteQuestion } from '@/api/questions.js'
   import { getQuestion, putQuestionApprove
      , putQuestionDisapprove } from '@/api/approvals.js'
   import { makeToast } from '@/api/toast.js'
   import { withProps, clearToasts } from 'mosha-vue-toastify'
   // Import UI components
   import { faHandPointLeft, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
   import ToastButton from '@/components/ToastButton.vue';
   import OptionEditor from '@/components/OptionEditor.vue';
   import OptionMaker from '@/views/questions/OptionMaker.vue';
   import ControlBar from '@/components/ControlBar.vue';
   import TagList from '@/components/TagList.vue';
   // Export component
   export default {
      name: 'QuestionEditor',
      props: {
         tags: {
            type: Array,
            default: () => {}
         },
         currentTag: {
            type: Object,
            default: () => {}
         },
         question: {
            type: Object,
            default: () => {}
         },
         questionAlreadyExists: {
            type: Boolean,
            default: false
         },
         questionIsUnapproved: {
            type: Boolean,
            default: false
         },
         previousViewCallback: {
            type: Function,
            default: () => {}
         },
         updateQuestionCallback: {
            type: Function,
            default: () => {}
         },
         refreshQuestionsForTag: {
            type: Function,
            default: () => {}
         },
      },
      data: () => {
         return {
            leftIcon: faHandPointLeft,
            editIcon: faEdit,
            deleteIcon: faTrash,
            modifiedQuestionText: null,
            modifiedQuestionSource: null,
            selectedTag: 1, // for select dropdown to add tag
            tagListSelected: null, // for tag list to delete tag
         }
      },
      computed: {
         questionText: {
            get() {
               return this.question.text
            },
            set(val) {
               this.modifiedQuestionText = val
            },
         },
         questionSource: {
            get() {
               return this.question.source
            },
            set(val) {
               this.modifiedQuestionSource = val
            },
         },
         questionHasAtLeastOneTag: function() {
            return this.question.tags && this.question.tags.length > 0
         },
         controlButtons: function() {
            let buttons = [
               {
                  text: 'Approved',
                  icon: 'thumbsUp',
                  disabled: !this.questionHasAtLeastOneTag,
                  variant: 'success',
                  onClickCallback: () => {
                     this.handleApproveQuestion()
                  }
               },
               {
                  text: 'Not Approved',
                  icon: 'thumbsDown',
                  variant: 'warning',
                  onClickCallback: () => {
                     this.handleDisapproveQuestion()
                  }
               },
               {
                  text: 'Delete Question',
                  icon: 'delete',
                  variant: 'danger',
                  onClickCallback: (e) => {
                     this.handleDeleteQuestion(e)
                  }
               },
            ]
            // Based on mode, return subset of buttons
            if(this.questionAlreadyExists) {
               buttons = buttons.slice(2)
            }
            return buttons
         },
      },
      components: {
         'OptionEditor': OptionEditor,
         'OptionMaker': OptionMaker,
         'ControlBar': ControlBar,
         'TagList': TagList,
      },
      methods: {
         tagClickCallback(tag) {
            console.log('tagClickCallback', tag)
            this.tagListSelected = tag
         },
         handleApproveQuestion() {
            const params = {
               question_id: this.question.id,
               axios: this.axios,
               callback: (res) => {
                  if(res.status === 201) {
                     makeToast('Question approved', 'success')
                     this.previousViewCallback()
                  } else if(res.response.status >= 400) {
                     makeToast(res.response.data.data.public, 'danger')
                  }
               }
            }
            // Make request to approve question
            putQuestionApprove(params)
         },
         handleDisapproveQuestion() {
            const params = {
               question_id: this.question.id,
               axios: this.axios,
               callback: (res) => {
                  if(res.status === 201) {
                     makeToast('Question not approved', 'warning')
                  } else if(res.response.status >= 400) {
                     makeToast(res.response.data.data.public, 'danger')
                  }
               }
            }
            // Make request to approve question
            putQuestionDisapprove(params)
         },
         // For use in option editor components
         refreshQuestionCallback() {
            if(this.questionIsUnapproved)
               this.refreshUnapprovedQuestion()
            else
               this.refreshQuestionsForTag()
         },
         refreshUnapprovedQuestion() {
            const params = {
               question_id: this.question.id,
               axios: this.axios,
               callback: (res) => {
                  if(res.status === 200) {
                     this.updateQuestionCallback(res.data.data)
                  } else if(res.response.status >= 400) {
                     makeToast(res.response.data.data.public, 'danger')
                  }
               }
            }
            // Make request to get updated question
            getQuestion(params)
         },
         handleCancelEdit(e) {
            e.preventDefault()
            // Exit this view
            this.previousViewCallback()
         },
         handleDeleteTag() {
            if(!this.tagListSelected) {
               return
            }
            const params = {
               question_id: this.question.id,
               tag_id: this.tagListSelected.id,
               axios: this.axios,
               callback: (res) => {
                  if(res.status === 201) {
                     makeToast('Question removed from tag', 'success')
                     this.refreshQuestionsForTag() // get updated question
                     this.previousViewCallback() // go back to question list
                  } else if(res.response.status >= 400) {
                     makeToast(res.response.data.data.public, 'danger')
                  }
               }
            }
            // Make request to remove question from tag
            deleteQuestionFromTag(params)
         },
         // Delete question confirmed
         confirmDeleteQuestionCallback() {
            const params = {
               id: this.question.id,
               axios: this.axios,
               callback: (res) => {
                  if(res.status === 201) {
                     clearToasts() // clear confirm toast
                     makeToast('Question deleted', 'success')
                     this.refreshQuestionsForTag() // get updated question
                     this.previousViewCallback() // go back to question list
                  } else if(res.response.status >= 400) {
                     makeToast(res.response.data.data.public, 'danger')
                  }
               }
            }
            // Make request to delete question
            deleteQuestion(params)
         },
         handleDeleteQuestion(e) {
            e.preventDefault()
            // callback, timeout, show close button
            makeToast(withProps(ToastButton
               , { message: 'Delete question?',
                  buttonClickCallback: this.confirmDeleteQuestionCallback })
               , 'danger', null, 10000)
         },
         handleSaveQuestion(e) {
            e.preventDefault()
            let params = {
               id: this.question.id,
               data: {
                  question_text: this.modifiedQuestionText ?? this.questionText,
                  source: this.modifiedQuestionSource ?? this.questionSource,
                  format: this.question.format,
               },
               axios: this.axios,
               callback: (res) => {
                  if(res.status === 204) {
                     makeToast('Question saved', 'success')
                     this.refreshQuestionsForTag() // get updated question
                     // if saving an approved question, above refresh call will return null
                     // for expected question because it's been set to unapproved
                     // force redirect to previous screen
                     if(!this.questionIsUnapproved) {
                        this.previousViewCallback() // go back to question list
                     }
                  } else if(res.response.status >= 400) {
                     makeToast('Error saving question', 'danger')
                  }
               }
            }
            // Make request to update question
            putQuestion(params)
         },
         handleSelectTag(e) {
            try {
               this.selectedTag = parseInt(e.target.id.replace('tag-', ''))
            } catch(e) {
               console.log(e)
               return false
            }
         },
         handleAddTag(e) {
            e.preventDefault()
            const params = {
               question_id: this.question.id,
               tag_id: this.selectedTag,
               axios: this.axios,
               callback: (res) => {
                  if(res.status === 201) {
                     makeToast('Question added into tag', 'success')
                     this.refreshQuestionsForTag() // get updated question
                     // this.previousViewCallback() // go back to question list
                  } else if(res.response.status >= 400) {
                     makeToast(res.response.data.data.public, 'danger')
                  }
               }
            }
            // Make request to add question to tag
            putQuestionIntoTag(params)
         },
      },
      setup() {
         // Inject axios and provide as prop to component for use with API calls
         const $axios = inject('$axios')
         return {
            axios: $axios
         }
      }
   }
</script>

<style scoped>
   form {
      display: inline-block;
      margin: 0;
      width: 100%;
   }
   textarea {
      width: calc(100% - 2.4em); /* compensate for padding */
      height: 9em;
      resize: none;
      border-radius: 0.2em;
      padding: 1em;
   }
   textarea.small {
      height: 2.5em;
   }
   select {
      padding: 0.4em 0.8em;
      font-size: 1.2rem;
      border-radius: 0.2em;
   }
   #tagSelector {
      text-align: right;
   }
   #editorWrapper {
      text-align: center;
      padding: 0 0 2em;
   }
   #questionEditor {
      padding: 0;
      border-width: 1px;
      border-style: solid;
      border-radius: 0.2em;
   }
   #questionEditor .flex-grid {
      padding-top: 1em;
   }
   #questionEditor .flex-grid.no-pad {
      padding-top: 0;
   }
   .tag-name {
      font-size: 1.0rem;
      position: absolute;
      right: 2em;
      top: 1.2em;
   }
   .tag-label {
      font-size: 1.5rem;
      position: relative; /* align with button */
      top: 0.3em;
   }
   .delete-button svg {
      margin-right: 0.5em;
   }
</style>
