import * as Utils from './utils.js'
import { $appConfig } from '@/config/app.js'

/*
* Get all questions for a tag.
*/
export function getQuestionsForTag(params) {
   if(Utils.isDebug()) console.log('getQuestionsForTag', params)
   // Configure get questions endpoint
   let endpoint = `${$appConfig.api.url}/managers/questions/tag/${params.id}`
   if(params.page && params.pageSize)
      endpoint += `?page=${params.page}&size=${params.pageSize}`
   // Call API
   params.axios.get(endpoint)
      .then(res => {
         if(params.callback) params.callback(res.data)
         return res.data
      }).catch(err => {
         console.log('getQuestionsForTag error', err)
      })
}

/*
* Update question.
*/
export function putQuestion(params) {
   if(Utils.isDebug()) console.log('putQuestion', params)
   // Call API
   params.axios.put(`${$appConfig.api.url}/managers/question/${params.id}`, params.data)
      .then(res => {
         if(params.callback) params.callback(res)
         return res
      }).catch(err => {
         console.log('putQuestion error', err)
         if(params.callback) params.callback(err)
      })
}

/*
* Delete question.
*/
export function deleteQuestion(params) {
   if(Utils.isDebug()) console.log('deleteQuestion', params)
   // Call API
   params.axios.delete(`${$appConfig.api.url}/managers/question/${params.id}`)
      .then(res => {
         if(params.callback) params.callback(res)
         return res
      }).catch(err => {
         console.log('deleteQuestion error', err)
         if(params.callback) params.callback(err)
      })
}

/*
* Update option.
*/
export function putOption(params) {
   if(Utils.isDebug()) console.log('putOption', params)
   // Call API
   params.axios.put(`${$appConfig.api.url}/managers/option/${params.id}`, params.data)
      .then(res => {
         if(params.callback) params.callback(res)
         return res
      }).catch(err => {
         console.log('putOption error', err)
         if(params.callback) params.callback(err)
      })
}

/*
* Delete option.
*/
export function deleteOption(params) {
   if(Utils.isDebug()) console.log('deleteOption', params)
   // Call API
   params.axios.delete(`${$appConfig.api.url}/managers/option/${params.id}`)
      .then(res => {
         if(params.callback) params.callback(res)
         return res
      }).catch(err => {
         console.log('deleteOption error', err)
         if(params.callback) params.callback(err)
      })
}
