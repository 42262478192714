<template>
   <div
      id="optionsWrapper"
      class="site-bg"
   >
      <!-- Options list -->
      <div class="flex-grid pad-bottom-1">
         <div class="col-xs col-label muted">
            Options
         </div>
         <div class="col-md">
            <ul class="option-list pad-right-1" >
               <li
                  v-for="opt in question.options"
                  :key="`option-${opt.id}`"
                  :class="`ultra-light-bg dark-border ${opt.is_correct ? 'correct' : ''}`"
               >
                  {{ opt.option_text }}
                  <div class="option-caption">
                     <FontAwesomeIcon
                        v-if="opt.is_correct"
                        :icon="correctIcon"
                        id="correctIcon"
                     />
                     {{ opt.is_correct ? 'Correct answer' : '' }}
                  </div>
                  <div id="optionButtons">
                     <button
                        v-on:click="handleOptionEdit(opt)"
                        :disabled="selectedOption"
                        :class="`option-button standard-bg
                           ${selectedOption ?
                              (opt.id === selectedOption.id ? 'highlight-bg' : 'not-selected')
                           : ''}
                        `"
                     >
                        <FontAwesomeIcon
                           :icon="editIcon"
                        />
                     </button>
                     <button
                        v-on:click="handleOptionDelete(opt.id)"
                        class="option-button danger-button"
                     >
                        <FontAwesomeIcon
                           :icon="deleteIcon"
                        />
                     </button>
                  </div>
                  <div class="option-format muted">
                     {{ opt.format }}
                  </div>
               </li>
            </ul>
         </div>
      </div>
      <!-- Option editor -->
      <div
         v-if="showOptionEditor"
         class="flex-grid pad-y-1"
      >
         <div class="col-xs col-label muted">
            Edit
         </div>
         <!-- form column -->
         <div class="col-md pad-right-1">
            <div>
               <form class="text-left">
                  <textarea
                     v-model="optionText"
                     class="option"
                  >
                  </textarea>
                  <div id="isCorrectControl">
                     <label>Is Correct?</label>
                     <input
                        type="checkbox"
                        v-model="isCorrect"
                     />
                  </div>
               </form>
            </div>
         </div><!-- form column-->
         <div class="col-xs">
            <div id="optionTextControlButtons">
               <button
                  v-on:click="handleSaveOption"
                  class="primary-button highlight-bg control-button"
               >
                  Save
               </button>
               <button
                  v-on:click="handleCancelOptionEdit"
                  class="ultra-light-bg control-button"
               >
                  Cancel
               </button>
            </div>
         </div>
      </div>
   </div><!-- optionsWrapper -->
</template>

<script>
   // Import functions
   import { inject } from 'vue'
   import { withProps, clearToasts } from 'mosha-vue-toastify'
   import { deleteOption, putOption } from '@/api/questions.js'
   import { deleteOptionFromUnapproved } from '@/api/approvals.js'
   import { makeToast } from '@/api/toast.js'
   // Import UI components
   import { faTrash, faEdit, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
   import ToastButton from '@/components/ToastButton.vue';
   // Import styles
   import '@/assets/css/options.css'
   // Export component
   export default {
      name: 'OptionEditor',
      props: {
         currentOption: {
            type: Object,
            default: () => {}
         },
         question: {
            type: Object,
            default: () => {}
         },
         refreshQuestionCallback: {
            type: Function,
            default: () => {}
         },
      },
      data: () => {
         return {
            editIcon: faEdit,
            deleteIcon: faTrash,
            correctIcon: faCheckCircle,
            showOptionEditor: false,
            modifiedOptionText: null,
            modifiedIsCorrect: null,
            selectedOption: null,
            refreshComputedCount: 0,
         }
      },
      computed: {
         optionText: {
            get() {
               this.refreshComputedCount // trigger to re-compute on selectedOption change
               return this.selectedOption.option_text
            },
            set(val) {
               this.modifiedOptionText = val
            },
         },
         isCorrect: {
            get() {
               this.refreshComputedCount // trigger to re-compute on selectedOption change
               return this.selectedOption.is_correct
            },
            set(val) {
               this.modifiedIsCorrect = val
            }
         }
      },
      components: {
      },
      methods: {
         handleSaveOption() {
            const params = {
               id: this.selectedOption.id,
               axios: this.axios,
               data: {
                  option_text: this.modifiedOptionText ?? this.optionText,
                  is_correct: this.modifiedIsCorrect ?? this.isCorrect,
                  format: this.selectedOption.format,
               },
               callback: (res) => {
                  if(res.status === 204) {
                     makeToast('Option updated', 'success')
                     // Reset form
                     this.selectedOption = null
                     this.showOptionEditor = false
                     // Refresh question
                     this.refreshQuestionCallback()
                  } else {
                     makeToast(res.response.data.data.public, 'danger')
                  }
               },
            }
            // Make request to save option
            putOption(params)
         },
         handleCancelOptionEdit() {
            // Reset editor
            this.selectedOption = null
            this.showOptionEditor = false
         },
         handleOptionEdit(opt) {
            // Select an option to edit
            this.selectedOption = opt
            this.showOptionEditor = true
            // Reset data model
            this.optionText = opt.option_text
            this.modifiedIsCorrect = opt.is_correct
            // Trigger refresh of computed properties
            this.refreshComputedCount = this.refreshComputedCount + 1
         },
         handleOptionDelete(id) {
            // Prompt user to confirm delete
            makeToast(withProps(ToastButton,
               { message: 'Delete option?', buttonClickCallback:
                  () => this.confirmDeleteOptionCallback(id) }
               ), 'danger', null, 10000)
         },
         // Delete option confirmed
         confirmDeleteOptionCallback(id) {
            const params = {
               question_id: this.question.id, // for deleteOptionFromUnapproved
               option_id: id, // ...
               id: id, // for deleteOption
               axios: this.axios,
               callback: (res) => {
                  if(res.status === 201) {
                     clearToasts() // clear confirm toast
                     makeToast('Option deleted', 'success')
                     // Refresh question
                     this.refreshQuestionCallback()
                  } else if(res.response.status >= 400) {
                     makeToast(res.response.data.data.public, 'danger')
                  }
               }
            }
            // Make request to delete option
            if(this.questionIsApproved) {
               deleteOption(params)
            } else {
               deleteOptionFromUnapproved(params)
            }
         },
      },
      setup() {
         // Inject axios and provide as prop to component for use with API calls
         const $axios = inject('$axios')
         return {
            axios: $axios
         }
      }
   }
</script>

<style scoped>
   textarea {
      width: calc(100% - 4em);
      height: 3.5em;
      padding: 1em;
      resize: none;
   }
   #optionsWrapper {
      text-align: center;
      padding: 0;
   }
</style>
